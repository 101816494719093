import Link from 'next/link';
import { classNames } from '../../utils/classNames';
import PropTypes from 'prop-types';

const elements = ['button', 'a'];

const baseClasses =
    'rounded-full inline-flex items-center gap-2 border-2 border-black shadow-none hover:shadow-md hover:translate-y-[-1px] transition-all hover:shadow-black/30 whitespace-nowrap transform-gpu';

const types = {
    primary: 'bg-black text-white',
    secondary: 'bg-transparent',
    tertiary: 'bg-sand border-0',
    iconOnly: '!p-1 !h-auto items-center justify-center',
    transparent: 'bg-transparent border-0 hover:bg-white/20 transition-all',
};

const sizes = {
    default: 'px-3 h-8 md:px-4 md:h-10 leading-0 text-small-copy md:text-base',
    small: 'px-3 h-8 leading-0 text-small-copy',
};

const disabled = {
    true: 'cursor-not-allowed opacity-50 hover:translate-y-0 hover:shadow-none',
    false: '',
};

const Button = (props) => {
    return (
        <>
            {props.element === 'a' && (
                <Link href={props.href}>
                    <a
                        className={classNames(types[props.type], sizes[props.size], baseClasses, props.className)}
                        target={props.targetBlank ? '_blank' : '_self'}>
                        {renderButtonContent(props)}
                    </a>
                </Link>
            )}
            {props.element === 'button' && (
                <button
                    type={props.isSubmit ? 'submit' : 'button'}
                    className={classNames(types[props.type], sizes[props.size], baseClasses, props.className, disabled[props.disabled])}
                    disabled={props.disabled}
                    onClick={(e) => {
                        if (props.disabled) {
                            return;
                        } else if (props.onClick) {
                            return props.onClick(e);
                        }
                    }}>
                    {renderButtonContent(props)}
                </button>
            )}
        </>
    );
};

function renderButtonContent(props) {
    const iconColor = props.iconColor ? `var(--color-${props.iconColor})` : 'inherit';
    return (
        <>
            {props.icon && (
                <span
                    className={classNames(
                        props.type === 'iconOnly' ? 'w-4 h-4 md:w-6 md:h-6 p-0 inline-flex items-stretch justify-items-stretch' : 'w-6 h-6'
                    )}
                    style={{ color: iconColor }}>
                    {props.icon}
                </span>
            )}
            {props.children}
        </>
    );
}

Button.defaultProps = {
    type: 'primary',
    size: 'default',
    element: 'button',
    href: '#',
    iconColor: '',
    onClick: undefined,
    disabled: false,
    targetBlank: false,
};

Button.propTypes = {
    type: PropTypes.oneOf(Object.keys(types)),
    size: PropTypes.oneOf(Object.keys(sizes)),
    element: PropTypes.oneOf(elements),
    href: PropTypes.string,
    iconColor: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    isSubmit: PropTypes.bool,
    targetBlank: PropTypes.bool,
};

export default Button;
