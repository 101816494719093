import React from 'react';
import { PropTypes } from 'prop-types';

import Peace from './Peace';
import Lightning from './Lightning';
import Daisy from './Daisy';
import Face from './Face';
import Heart from './Heart';
import Kete from './Kete';
import KeteStrandOne from './KeteStrandOne';
import KeteStrandTwo from './KeteStrandTwo';
import KeteStrandThree from './KeteStrandThree';
import Poppy from './Poppy';
import Sun from './Sun';

export const stickerMap = {
    daisy: Daisy,
    face: Face,
    lightning: Lightning,
    peace: Peace,
    poppy: Poppy,
    sun: Sun,
    kete: Kete,
    keteStrandOne: KeteStrandOne,
    keteStrandTwo: KeteStrandTwo,
    keteStrandThree: KeteStrandThree,
    heart: Heart,
}

const Sticker = ({name, color, className}) => {
    if(!Object.keys(stickerMap).includes(name)) return (<></>);
    return React.createElement(stickerMap[name], {color, className})
}

Sticker.propTypes = {
    name: PropTypes.oneOf(Object.keys(stickerMap))
}

export {
    Peace,
    Lightning,
    Daisy,
    Face,
    Heart,
    Kete,
    Poppy,
    Sun,
    Sticker
};