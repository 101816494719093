export default function Kete(props) {
  return (
    <svg viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path className="fill-black" d="M11.0254 102.455V52.7869C11.0254 52.5819 11.0702 52.3793 11.1566 52.1934C11.2429 52.0075 11.3689 51.8428 11.5255 51.7108L21.5112 43.294C21.6689 43.1612 21.7954 42.9952 21.8818 42.8079C21.9682 42.6207 22.0124 42.4167 22.0113 42.2104C22.0102 42.0041 21.9638 41.8006 21.8754 41.6143C21.7869 41.4279 21.6587 41.2634 21.4996 41.1322L12.683 33.8616C12.5237 33.7302 12.3953 33.5654 12.3069 33.3787C12.2185 33.1921 12.1722 32.9883 12.1713 32.7817C12.1704 32.5751 12.215 32.3709 12.3019 32.1836C12.3887 31.9962 12.5157 31.8303 12.6739 31.6976L42.7467 6.46729C42.9996 6.25512 43.3191 6.13884 43.649 6.13884C43.979 6.13884 44.2984 6.25512 44.5513 6.46729L54.0984 14.4766C54.3513 14.6888 54.6708 14.8051 55.0007 14.8051C55.3307 14.8051 55.6501 14.6888 55.903 14.4766L65.4495 6.46723C65.7024 6.25507 66.0219 6.13879 66.3518 6.13879C66.6818 6.13879 67.0012 6.25507 67.2541 6.46723L97.3028 31.6765C97.4602 31.8085 97.5867 31.9735 97.6736 32.1597C97.7604 32.346 97.8054 32.5491 97.8054 32.7547C97.8054 32.9602 97.7604 33.1633 97.6736 33.3496C97.5867 33.5359 97.4602 33.7008 97.3028 33.8328L88.4999 41.2182C88.3423 41.3504 88.2156 41.5157 88.1287 41.7023C88.0419 41.8889 87.997 42.0923 87.9973 42.2982C87.9975 42.5041 88.0429 42.7074 88.1303 42.8937C88.2176 43.0801 88.3447 43.245 88.5027 43.3769L98.4702 51.6957C98.6284 51.8277 98.7557 51.9929 98.843 52.1796C98.9303 52.3663 98.9756 52.57 98.9756 52.7761V102.455C98.9756 102.639 98.9392 102.822 98.8686 102.993C98.798 103.164 98.6945 103.319 98.5641 103.449C98.4336 103.58 98.2788 103.684 98.1083 103.754C97.9379 103.825 97.7552 103.861 97.5707 103.861H12.4303C12.0577 103.861 11.7004 103.713 11.4369 103.449C11.1735 103.186 11.0254 102.828 11.0254 102.455Z" />
      <g fill={`var(--color-${props.color || 'cream'})`}>
        <path d="M44.5479 75.8524H65.4555L55.0016 67.0826L44.5479 75.8524Z" />
        <path d="M27.1712 90.4299L16.625 99.2784H93.377L82.8306 90.4299H27.1712Z" />
        <path d="M30.585 87.5662H79.4156L68.8679 78.7178H41.1323L30.585 87.5662Z" />
        <path d="M27.6616 44.1475L15.6025 54.2649V65.815L34.5455 49.9226L27.6616 44.1475Z" />
        <path d="M82.338 44.1461L75.4541 49.9223L94.3985 65.8148V54.2634L82.338 44.1461Z" />
        <path d="M36.7736 51.7911L15.6025 69.5531V81.1057L43.6575 57.5674L36.7736 51.7911Z" />
        <path d="M66.3418 57.5674L94.3978 81.1057V69.5531L73.2257 51.7911L66.3418 57.5674Z" />
        <path d="M45.8869 59.4365L15.6025 84.8444V96.3954L52.7708 65.2127L45.8869 59.4365Z" />
        <path d="M64.1143 55.6985L91.4616 32.7547L84.5776 26.9783L57.2305 49.9222L64.1143 55.6985Z" />
        <path d="M18.54 32.7547L94.3964 96.3954V84.8445L25.4239 26.9797L18.54 32.7547Z" />
        <path d="M64.1137 40.4085L82.3488 25.1093L75.4634 19.3342L57.2295 34.6321L64.1137 40.4085Z" />
        <path d="M61.8862 42.2782L34.5403 19.3356L27.6553 25.1107L55.0023 48.0545L61.8862 42.2782Z" />
        <path d="M73.2362 17.4645L66.3524 11.6894L57.2305 19.3427L64.1131 25.1178L73.2362 17.4645Z" />
        <path d="M61.8825 26.9861L43.6485 11.6894L36.7646 17.4645L54.9986 32.7625L61.8825 26.9861Z" />
      </g>
    </svg>
  );
}