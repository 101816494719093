export default function Lightning(props) {
  return (
    <svg viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path fill={`var(--color-${props.color || 'granite' })`} d="M55.0001 106.99C47.9842 106.99 41.1758 105.615 34.7641 102.903C28.5876 100.293 22.9763 96.5102 18.2415 91.7625C13.4944 87.0272 9.71147 81.4153 7.10269 75.2383C4.39071 68.8257 3.01562 62.0165 3.01562 55.0001C3.01562 47.9836 4.39071 41.1743 7.10269 34.7619C9.71146 28.5848 13.4944 22.9728 18.2415 18.2374C22.9763 13.4898 28.5876 9.7065 34.764 7.09744C41.1758 4.38517 47.9842 3.00989 55 3.00989C62.0157 3.00989 68.8242 4.38517 75.2359 7.09744C81.4124 9.70647 87.0237 13.4898 91.7586 18.2374C96.5057 22.9727 100.289 28.5846 102.897 34.7617C105.609 41.1742 106.984 47.9834 106.984 54.9999C106.984 62.0163 105.609 68.8256 102.897 75.238C100.289 81.4151 96.5057 87.027 91.7586 91.7623C87.0238 96.51 81.4126 100.293 75.2362 102.903C68.8243 105.615 62.0159 106.99 55.0001 106.99Z" />
      <path d="M70.6748 79.6049C69.5565 79.6049 68.4633 79.2733 67.5335 78.6519C66.6036 78.0306 65.8789 77.1474 65.4509 76.1141C65.023 75.0808 64.911 73.9438 65.1291 72.8469C65.3473 71.75 65.8858 70.7424 66.6766 69.9515C67.4673 69.1606 68.4748 68.6221 69.5716 68.4038C70.6684 68.1856 71.8053 68.2976 72.8385 68.7256C73.8717 69.1536 74.7548 69.8783 75.3761 70.8082C75.9975 71.7382 76.3291 72.8315 76.3292 73.9499C76.3275 75.4492 75.7313 76.8866 74.6712 77.9467C73.6112 79.0069 72.174 79.6032 70.6748 79.6049ZM70.6748 71.0038C70.0922 71.0038 69.5226 71.1766 69.0381 71.5003C68.5536 71.8241 68.176 72.2842 67.953 72.8226C67.7301 73.361 67.6717 73.9534 67.7854 74.5249C67.8991 75.0964 68.1796 75.6214 68.5917 76.0335C69.0037 76.4455 69.5286 76.7262 70.1001 76.8398C70.6716 76.9535 71.2639 76.8952 71.8022 76.6722C72.3406 76.4492 72.8007 76.0715 73.1244 75.587C73.4481 75.1025 73.6209 74.5328 73.6209 73.9501C73.62 73.169 73.3093 72.42 72.757 71.8677C72.2047 71.3153 71.4559 71.0046 70.6748 71.0038Z" fill="#231F20"/>
      <path d="M54.9994 10.3251C58.0241 10.3243 61.0411 10.6284 64.0048 11.2328C66.8776 11.8213 69.6851 12.6929 72.3862 13.8348C75.0421 14.9594 77.5829 16.3387 79.9729 17.9533C82.3458 19.5576 84.5602 21.3849 86.5858 23.4101C88.6109 25.436 90.4379 27.6506 92.0421 30.0238C93.6565 32.4141 95.0357 34.9551 96.1602 37.6114C97.302 40.3127 98.1735 43.1205 98.7619 45.9936C99.3663 48.9576 99.6703 51.975 99.6695 55C99.6703 58.025 99.3663 61.0424 98.7619 64.0065C98.1735 66.8796 97.302 69.6873 96.1602 72.3887C95.0357 75.0449 93.6565 77.586 92.0421 79.9763C90.4379 82.3495 88.6109 84.5641 86.5858 86.59C84.5602 88.6152 82.3458 90.4425 79.9729 92.0468C77.5829 93.6614 75.0421 95.0407 72.3862 96.1654C69.6851 97.3072 66.8776 98.1788 64.0048 98.7673C61.0411 99.3717 58.0241 99.6758 54.9994 99.675C51.9747 99.6758 48.9577 99.3717 45.994 98.7673C43.1212 98.1788 40.3137 97.3072 37.6127 96.1654C34.9567 95.0407 32.4159 93.6614 30.0258 92.0469C27.6529 90.4425 25.4386 88.6153 23.4129 86.59C21.3879 84.5641 19.5608 82.3495 17.9567 79.9763C16.3423 77.586 14.9631 75.0449 13.8386 72.3887C12.6969 69.6873 11.8254 66.8796 11.2369 64.0065C10.6326 61.0424 10.3286 58.025 10.3293 55C10.3286 51.975 10.6326 48.9576 11.2369 45.9936C11.8254 43.1205 12.6969 40.3127 13.8386 37.6114C14.9632 34.9551 16.3423 32.4141 17.9567 30.0238C19.5608 27.6506 21.3879 25.436 23.4129 23.4101C25.4386 21.3849 27.6529 19.5576 30.0258 17.9533C32.4159 16.3387 34.9567 14.9594 37.6127 13.8348C40.3137 12.6929 43.1212 11.8213 45.994 11.2328C48.9577 10.6284 51.9747 10.3243 54.9994 10.3251ZM54.9994 7.61658C28.8331 7.61658 7.62109 28.8309 7.62109 55C7.62109 81.1692 28.8333 102.384 54.9994 102.384C81.1655 102.384 102.378 81.1692 102.378 55C102.378 28.8308 81.1657 7.61685 54.9997 7.61685L54.9994 7.61658Z" fill="#231F20"/>
      <path d="M82.7029 57.6949C82.2315 57.2238 81.7334 56.7803 81.2109 56.3666V47.0187C81.2109 41.2085 76.4844 36.4814 70.6747 36.4814C64.865 36.4814 60.1385 41.2085 60.1385 47.0187V56.3666C59.6161 56.7803 59.1179 57.2239 58.6466 57.695C58.3927 57.949 58.25 58.2934 58.25 58.6526C58.25 59.0118 58.3927 59.3562 58.6466 59.6102C58.7722 59.7362 58.9215 59.836 59.0858 59.9041C59.2501 59.9722 59.4263 60.0071 59.6041 60.0069C59.782 60.0071 59.9581 59.9722 60.1224 59.9041C60.2868 59.836 60.436 59.7361 60.5616 59.6102C61.8891 58.2809 63.4655 57.2264 65.2008 56.5069C66.9361 55.7874 68.7962 55.4171 70.6747 55.4171C72.5532 55.4171 74.4133 55.7874 76.1486 56.5069C77.8839 57.2264 79.4603 58.2809 80.7878 59.6102C80.9135 59.736 81.0628 59.8357 81.2271 59.9038C81.3914 59.9718 81.5675 60.0069 81.7453 60.0069C81.9232 60.0069 82.0992 59.9718 82.2635 59.9038C82.4278 59.8357 82.5771 59.736 82.7029 59.6102C82.8286 59.4844 82.9284 59.3351 82.9964 59.1708C83.0645 59.0065 83.0995 58.8304 83.0995 58.6526C83.0995 58.4747 83.0645 58.2986 82.9964 58.1343C82.9284 57.97 82.8286 57.8207 82.7029 57.6949ZM62.8467 47.0187C62.8467 42.7019 66.3583 39.19 70.6747 39.19C71.9494 39.1903 73.2047 39.5021 74.3315 40.0982C75.4583 40.6943 76.4225 41.5567 77.1401 42.6103C76.9008 42.5915 76.6591 42.5821 76.4149 42.5819C73.9797 42.5819 71.6442 43.5494 69.9222 45.2715C68.2002 46.9937 67.2328 49.3294 67.2328 51.7649V53.0563C65.7057 53.3686 64.2296 53.8919 62.8467 54.611V47.0187Z" fill="#231F20"/>
      <path d="M51.3533 57.6949C50.8819 57.2238 50.3838 56.7803 49.8614 56.3666V47.0187C49.8614 41.2085 45.1348 36.4814 39.3251 36.4814C33.5154 36.4814 28.7889 41.2085 28.7889 47.0187V56.3666C28.2665 56.7803 27.7683 57.2238 27.297 57.695C27.0431 57.949 26.9004 58.2934 26.9004 58.6526C26.9004 59.0118 27.0431 59.3562 27.297 59.6102C27.4226 59.7361 27.5718 59.836 27.736 59.9041C27.9003 59.9721 28.0764 60.0071 28.2543 60.0069C28.4321 60.0071 28.6083 59.9722 28.7726 59.9041C28.9369 59.836 29.0862 59.7361 29.2118 59.6102C30.5365 58.2772 32.1125 57.2203 33.8484 56.5005C35.5843 55.7808 37.4458 55.4125 39.3249 55.4171C41.2042 55.4125 43.0656 55.7807 44.8016 56.5005C46.5375 57.2202 48.1135 58.2772 49.4382 59.6102C49.564 59.736 49.7132 59.8357 49.8775 59.9038C50.0418 59.9718 50.2179 60.0069 50.3957 60.0069C50.5736 60.0069 50.7497 59.9718 50.914 59.9038C51.0783 59.8357 51.2275 59.736 51.3533 59.6102C51.479 59.4844 51.5788 59.3351 51.6468 59.1708C51.7149 59.0065 51.7499 58.8304 51.7499 58.6526C51.7499 58.4747 51.7149 58.2986 51.6468 58.1343C51.5788 57.97 51.479 57.8207 51.3533 57.6949ZM31.4972 47.0187C31.4972 42.7019 35.0087 39.19 39.3251 39.19C40.5981 39.1903 41.8517 39.5013 42.9774 40.0958C44.103 40.6903 45.0666 41.5505 45.7846 42.6018C45.5854 42.5888 45.3845 42.5822 45.182 42.5819C43.9762 42.5819 42.7822 42.8194 41.6682 43.2809C40.5542 43.7424 39.5419 44.4188 38.6893 45.2715C37.8366 46.1242 37.1603 47.1366 36.6988 48.2507C36.2374 49.3648 35.9999 50.559 35.9999 51.7649V53.0321C34.4315 53.3417 32.9153 53.8734 31.4971 54.6112L31.4972 47.0187Z" fill="#231F20"/>
    </svg>
  );
}